import { createContext, useState, useEffect } from "react";

export const ModalContext = createContext<any>(null);

interface IContext {
  children: React.ReactNode;
}

export const ModalProvider = ({ children }: IContext) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleUrlTime = () => {
    const url = window.location.search.split("?")[1];

    if (modalOpen) {
      window.parent.postMessage("collapse", url);
    } else {
      window.parent.postMessage("expand", url);
    }
  };

  useEffect(() => {
    if (!sessionStorage.getItem("tryadviser-opened")) {
      // setTimeout(() => {
      //   handleUrlTime();
      //   setModalOpen(true);
      //   sessionStorage.setItem("tryadviser-opened", "true");
      // }, 10000);
    }
    const url = window.location.search.split("?")[1];
    window.addEventListener("message", (e) => {
      if (e.data === "tryadviser_open_modal") {
        window.parent.postMessage("expand", url);
        setModalOpen(true);
      }
    });
  }, []);

  const close = () => {
    // if (!window.sessionStorage.getItem("tryadviser-closed")) {

    //   window.sessionStorage.setItem("tryadviser-closed", "true");
    // }

    setModalOpen(false);

    const url = window.location.search.split("?")[1];
    // console.log(url);

    if (modalOpen) {
      window.parent.postMessage("collapse", url);
    } else {
      window.parent.postMessage("expand", url);
    }
  }

  const open = () => {
    setModalOpen(true);
  };

  return (
    <ModalContext.Provider value={[modalOpen, setModalOpen, open, close]}>
      {children}
    </ModalContext.Provider>
  );
};
