import { useContext, useEffect, useState } from "react";
import { T } from "../../styles/Theme";
import { Text, Title } from "../../styles/Texts";
import { Alternative, Data } from "../../Interfaces/IQuestion";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ClockLoader } from "react-spinners";
import axios from "axios";
import { ADVISER_IDENTIFIER, QUESTIONS_API_URL } from "../../env";
import { StageProgressBar } from "../../components/StageProgressBar";
import { GenericButton } from "../../components/UI-KIT/GenereicButton";
import { HeaderQuestionStyled, ContainerQuestionStyled, ClockStyled } from "../../styles/Screens/Question";
import AlternativeButton from "../../components/UI-KIT/AlternativeButton";
// import { EssentialsDataContext } from "../../Contexts/EssentialsDataContext";
import { CheckBox } from "../../components/UI-KIT/CheckBox";
import TextValues from "../../TextValues/index.json";
import styled from "styled-components";
// import { InformationIcon } from "../../assets/Icons/InformationIcon";
// import Tooltip from "rc-tooltip";
// import 'rc-tooltip/assets/bootstrap.css';
import { EssentialsDataContext } from "../../contexts/EssentialsDataContext";
import { generateDataEvent } from "../../utils/analytics";
import { uploadEventRequest } from "../../utils/requests";

export const Question = () => {
  const [questionData, setQuestionData] = useState<Data | null>(null);
  const [currentStep, setCurrentStep] = useState<any>(1);

  const [idRedirection, setIdRedirection] = useState<null | number>(null);

  const [
    finalForm,
    setFinalForm,
    screenNumber,
    setScreenNumber,
    previousScreens,
    setPreviousScreens,
    ,
    ,
    questionId,
    setQuestionId,
    currentPage,
    setCurrentPage,
  ] = useContext(EssentialsDataContext);

  const [loading, setLoading] = useState(true);
  const [infoAlternative, setInfoAlternative] = useState<any>({});

  useEffect(() => {

    const index = finalForm.findIndex(
      (el: any) => el.id_question === questionId
    );
    
    if (index !== -1) {
      setIdRedirection(finalForm[index].alt_redirection);
    }

    if (questionId || questionId !== 0) {
      try {
        setLoading(true);
        axios.get(`${QUESTIONS_API_URL}/${ADVISER_IDENTIFIER}/questions/${questionId}/`)
          .then((res) => {
            setQuestionData(res.data.data[0]);
            setCurrentStep(res.data.data[0].stage.id);
          })
          .then(() => {
            const index = previousScreens.findIndex(
              (el: any) => el.currentPage === currentPage
            );

            if (index === -1) {
              setPreviousScreens([
                ...previousScreens,
                { currentPage: currentPage, previousID: questionId },
              ]);
            } else {
              previousScreens[index].previousID = questionId;
            }
          })
          .finally(() => {
            setLoading(false);
          })
          .catch(error => {
            if (questionId === null) {
              setScreenNumber( screenNumber + 1);
            }
          })
      } catch (error) {
        
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  const handleValidateFooter = () => {
    const index = finalForm.findIndex(
      (el: any) => el.id_question === questionId
    );

    if (index === -1) {
      return true;
    }
  };

  const handleSelection = (alt: Alternative, question: Data) => {
    const index = finalForm.findIndex(
      (el: any) => el.id_question === question.id
    );

    if (index !== -1) {
      const indexToDelete: number[] = [];

      finalForm.forEach((el: any) => {
        const indexDelete = finalForm.findIndex((item: any) => {
          return item.id_question > finalForm[index].id_question;
        });

        if (indexDelete !== -1) {
          indexToDelete.push(indexDelete);
        }
      });

      indexToDelete.forEach((el: any) => {
        const arr = finalForm.splice(el, 1);
        setFinalForm(arr);
      });

      const arr = finalForm.splice(index, 1);
      setFinalForm(arr);
    }

    const indexAlternative = question.alternatives.findIndex(
      (el:any) => el.id === alt.id
    );
    let arrAnswer = Array(question.alternatives.length).fill(0);

    arrAnswer[indexAlternative] = 1;

    setFinalForm([
      ...finalForm,
      {
        id_question: question.id,
        answer: arrAnswer,
        title: question.title,
        id_alt: alt.id,
        alternative_text: alt.title,
        alt_redirection: alt.next_question_id,
      },
    ]);
    setIdRedirection(alt.next_question_id);
  };

  const handleMultipleSelection = (alt: Alternative, question: Data) => {
    const index = finalForm.findIndex(
      (el: any) => el.id_question === question.id
    );

    if (index !== -1) {
      const indexToDelete: number[] = [];

      finalForm.forEach((el: any) => {
        const indexDelete = finalForm.findIndex((item: any) => {
          return item.id_question > finalForm[index].id_question;
        });

        if (indexDelete !== -1) {
          indexToDelete.push(indexDelete);
        }
      });

      indexToDelete.forEach((el: any) => {
        const arr = finalForm.splice(el, 1);
        setFinalForm(arr);
      });
    }

    const AltText = finalForm[index] ? finalForm[index].alternative_text : [];
    if (!finalForm[index]?.alternative_text.includes(alt.title)) {
      AltText.push(alt.title);
    } else {
      const indexItem = finalForm[index]?.alternative_text.indexOf(
        alt.title
      );
      if (indexItem > -1) {
        finalForm[index]?.alternative_text.splice(indexItem, 1);
      }
    }

    let arrAnswer = finalForm[index]
      ? finalForm[index].answer
      : Array(question.alternatives.length).fill(0);

    const AltSelected = finalForm[index] ? finalForm[index].id_alt : [];
    if (!finalForm[index]?.id_alt.includes(alt.id)) {
      AltSelected.push(alt.id);
    } else {
      const indexItem = finalForm[index]?.id_alt.indexOf(alt.id);
      if (indexItem > -1) {
        finalForm[index]?.id_alt.splice(indexItem, 1);
      }
    }

    const indexAlternative = question.alternatives.findIndex(
      (alternative) => alternative.id === alt.id
    );

    if (indexAlternative !== -1) {
      if (arrAnswer[indexAlternative] === 1) {
        arrAnswer[indexAlternative] = 0;
      } else {
        arrAnswer[indexAlternative] = 1;
      }
    }

    const alternativesArr: any = finalForm[index]
      ? finalForm[index].alternatives
      : [];
    const indexAlt = alternativesArr.findIndex(
      (item: any) => item.id_alternative === alt.id
    );
    if (indexAlt !== -1) {
      alternativesArr.splice(indexAlt, 1);
    } else {
      alternativesArr.push({
        id_alternative: alt.id,
        alternative_text: alt.title,
      });
    }

    if (index !== -1) {
      const arr = finalForm.splice(index, 1);
      setFinalForm(arr);
    }

    const finalObject = {
      id_question: question.id,
      answer: arrAnswer,
      title: question.title,
      id_alt: AltSelected,
      alternative_text: AltText,
      alt_redirection: alt.next_question_id,
      alternatives: alternativesArr,
    };

    setFinalForm([...finalForm, finalObject]);
    setIdRedirection(alt.next_question_id);
  };

  const handleValue = (question_id: number) => {
    const index = finalForm.findIndex(
      (el: any) => el.id_question === question_id
    );

    if (index !== -1) {
      return finalForm[index].id_alt;
    }
  };

  const handleMultipleValue = (question_id: number, alt: number) => {
    const index = finalForm.findIndex(
      (el: any) => el.id_question === question_id
    );

    if (index !== -1) {
      return finalForm[index].id_alt.includes(alt);
    }
  };

  // NAVIGATION
  const nextScreen = () => {
    setInfoAlternative({});
    const index = previousScreens.findIndex(
      (el: any) => el.currentPage === currentPage + 1
    );

    if (idRedirection !== 0 && !loading) {
      setCurrentPage(currentPage + 1);
        if (index === -1) {
      }

      setQuestionId(idRedirection);
    } else {
      setScreenNumber(screenNumber + 1);
    }

    // ANALYTICS
    const code: string = `${ADVISER_IDENTIFIER}[7g872job@b8c3.campoalto]`;
    const body: any = {
      data: generateDataEvent(questionData?.id || 0, questionData?.title || "")
    } 
    uploadEventRequest(JSON.stringify(body), code).then((res) => void(0));
  };

  const prevScreen = () => {
    if (screenNumber === 1 && !loading) {
      if (questionId === 1) {
        setScreenNumber(screenNumber - 1);
      } else {
        const index = previousScreens.findIndex(
          (el: any) => el.currentPage === currentPage - 1
        );

        setQuestionId(previousScreens[index].previousID);
        setCurrentPage(currentPage - 1);
      }
    }
  };

  const handleInfoAlternative = (value: string) => {
    const obj: any = {};
    obj[`${value}`] = infoAlternative[`${value}`] ? false : true;
    setInfoAlternative({
      ...obj,
    });
  }

  return (
    <>
      <HeaderQuestionStyled>
        <StageProgressBar step={1} currentStep={currentStep} />
      </HeaderQuestionStyled>
      <ContainerQuestionStyled>
        <Title>
          {questionData ? questionData.title : <Skeleton />}
        </Title>
        {questionData?.description !== "" && (
          <Text>{questionData?.description}</Text>
        )}

        {questionData ? (
          <>
            {!questionData?.multiple_choice && (
              <div className={(questionData?.alternatives.length > 6) ? "alternativesContainerMulti" : "alternativesContainer"}>
                {questionData?.alternatives.map(
                  (alt: Alternative, i: number) => {
                    return (
                      <>
                      <ContaitnerAlternative>
                        {/* {(alt.description) ? (
                          <span className="iconContainer">
                            <Tooltip
                              placement="rightTop"
                              visible={infoAlternative[`${i}info`]}
                              overlay={
                                <div style={{
                                  maxWidth: 200,
                                  fontSize: 12,
                                  color: '#FFFFFF',
                                }}>
                                    <span>     
                                      {alt.description}
                                    </span>
                                </div>
                              }
                              onVisibleChange={() => handleInfoAlternative(`${i}info`)}
                              trigger="click"
                            >
                              <InformationIcon
                                className="icon"
                              />
                            </Tooltip>
                          </span>
                        ): (
                          <span className="iconHidden">
                            <p></p>
                          </span>
                        )} */}
                        <AlternativeButton
                        tag={`${i}`}
                        key={`${alt.id} -- ${alt.title}`}
                        name={`${questionData?.title}`}
                        id={`${alt.id} -- ${alt.title}`}
                        text={alt.title}
                        onClick={() => handleSelection(alt, questionData)}
                        checked={handleValue(questionData?.id) === alt.id}
                        />
                      </ContaitnerAlternative>
                      </>
                    );
                  }
                )}
              </div>
            )}

            {questionData?.multiple_choice && (
              <div className={(questionData?.alternatives.length > 6) ? "alternativesContainerMulti" : "alternativesContainer"}>
                {questionData?.alternatives.map(
                  (alt: Alternative, i: number) => {
                    return (
                      <ContaitnerAlternative>
                        {/* {(alt.description) && (
                          <span className="iconContainer">
                            <Tooltip
                              placement="rightTop"
                              visible={infoAlternative[`${i}info`]}
                              overlay={
                                <div style={{
                                  maxWidth: 200,
                                  fontSize: 12,
                                  color: '#FFFFFF',
                                }}>
                                    <span>     
                                      {alt.description}
                                    </span>
                                </div>
                              }
                              onVisibleChange={() => handleInfoAlternative(`${i}info`)}
                              trigger="click"
                            >
                              <InformationIcon
                                className="icon"
                              />
                            </Tooltip>
                          </span>
                        )} */}
                        <CheckBox
                        tag={`${i}`}
                        key={`${alt.id} -- ${alt.title}`}
                        name={`${questionData?.title}`}
                        id={`${alt.id} -- ${alt.title}`}
                        text={alt.title}
                        onClick={() => handleMultipleSelection(alt, questionData)}
                        checked={handleMultipleValue(questionData?.id, alt.id)}
                        />
                      </ContaitnerAlternative>
                    );
                  }
                )}
              </div>
            )}
          </>
        ) : (
          <div className="alternativesContainer">
            <Title><Skeleton /></Title>
            <Text><Skeleton count={5} /></Text>
          </div>
        )}

        <div className="buttonContainer">
          <GenericButton
            bg="transparent"
            c={T.primary}
            onClick={prevScreen}
            disabled={loading}
          >
            {TextValues.buttons.previous}
          </GenericButton>
          
          <GenericButton
            bg={T.primary}
            c="white"
            onClick={nextScreen}
            disabled={handleValidateFooter() || loading}
          >
            {loading ? (
              <ClockStyled>
                <ClockLoader size={30} color="white" />
              </ClockStyled>
            ) : (
              TextValues.buttons.next
            )}
          </GenericButton>
        </div>
      </ContainerQuestionStyled>
    </>
  );
};

const ContaitnerAlternative = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .iconContainer {
    display: flex;
    align-items: center;
  }

  .iconHidden {
    margin-left: 16px;
  }

  .icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    // On click scale the icon
    &:hover {
      transform: scale(1.1);
    }

    &:active {
      transform: scale(0.90);
    }
  }

  @media (max-width: 500px) {
    margin-left: 20px;

    .iconHidden {
      margin-left: 16px;
    }
  }
`;