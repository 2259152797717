import axios from "axios";
import { SENDGRID_SERVICE_URL, QUESTIONS_API_URL, ADVISER_IDENTIFIER, CHAT_BETA_URL, SERVICES_API_URL, PREDICT_API_URL, ANSWERS_API_URL, SEND_PROMPT_API, CONTACT_API_URL, SENDGRID_RECOMMENTATIONS_URL, CHAT_RECORDS_API_URL, CRM_INTERACTION_URL, ANALYTICS_API_URL } from "../../env";

const config = {
  headers: {
    "Content-Type": "application/json"
  }
};

export const sendNotificationEmail = (body: any) => {
  return axios.post(`${SENDGRID_SERVICE_URL}`, body, config)
}

export const getInitialSettings = () => {
  return axios.get(`${QUESTIONS_API_URL}/${ADVISER_IDENTIFIER}/settings/`)
}

export const getStagesRequest = () => {
  return axios.get(`${QUESTIONS_API_URL}/${ADVISER_IDENTIFIER}/stages/`)
}

export const getQuestionsRequest = (questionId: number) => {
  return axios.get(`${QUESTIONS_API_URL}/${ADVISER_IDENTIFIER}/questions/${questionId}/`)
}

export const getChatPredictionRequest = (body: any) => {
  return axios.post(`${CHAT_BETA_URL}/predict/`, body, config)
}

export const getRecommendedServicesRequest = (body: any) => {
  return axios.post(`${SERVICES_API_URL}/${ADVISER_IDENTIFIER}/services/recommended/`, body, config)
}

export const getResultPredictedRequest = (body: any) => {
  return axios.post(`${PREDICT_API_URL}/api/v1/predict/`, body, config)
}

export const uploadAnswersRequest = (body: any) => {
  return axios.post(`${ANSWERS_API_URL}/${ADVISER_IDENTIFIER}/answers/`, body, config)
}

export const uploadEmailToAnswersRequest = (body: any) => {
  return axios.post(`${ANSWERS_API_URL}/${ADVISER_IDENTIFIER}/answers/emails/`, body)
}

export const uploadRateRequest = (body: any) => {
  return axios.post(`${ANSWERS_API_URL}/${ADVISER_IDENTIFIER}/answers/rates/`, body)
}

export const updateAnswerWithServiceSelectedRequest = (answerId: number, body: any) => {
  return axios.put(`${ANSWERS_API_URL}/${ADVISER_IDENTIFIER}/answers/${answerId}/`, body, config)
}

// CHAT
export const sendPrompt = ( body: any ) => {
  return axios.post(`${SEND_PROMPT_API}/${ADVISER_IDENTIFIER}/chat/`, body)
}

export const uploadContactDataRequest = (data: any) => {
  return axios.post(`${CONTACT_API_URL}/${ADVISER_IDENTIFIER}/contacts/`, data, config)
}

export const sendRecommendationsEmail = (body: any) => {
  return axios.post(`${SENDGRID_RECOMMENTATIONS_URL}`, body, config)
}

export const uploadCommentRequest = (body: any) => {
  return axios.post(`${ANSWERS_API_URL}/${ADVISER_IDENTIFIER}/answers/comments/`, body, config)
}

export const uploadChatRequest = (body: any) => {
  return axios.post(`${CHAT_RECORDS_API_URL}/${ADVISER_IDENTIFIER}/chats/`, body, config)
};

export const updateChatRequest = (body: any, chatId: number) => {
  return axios.put(`${CHAT_RECORDS_API_URL}/${ADVISER_IDENTIFIER}/chats/${chatId}/`, body, config)
};

export const sendHubspotData = (body: any, idAnswer: number) => {
  const code: string = btoa(`${ADVISER_IDENTIFIER}[3f56u3@ndjw35.ca]`);
  return axios.post(`${CRM_INTERACTION_URL}/api/v1/${code}/contact/${idAnswer}/`, body, config)
}

export const uploadEventRequest = (data: any, adviserCode: string) => {
  return axios.post(`${ANALYTICS_API_URL}/${btoa(adviserCode || "")}/analytics/new/`, data, config)
}

export const uploadRecommendationsRquest = (body: any) => {
  return axios.post(`${ANSWERS_API_URL}/${ADVISER_IDENTIFIER}/answers/recommendations/`, body, config)
}