import styled from "styled-components";
import welcomeImage from "../../../assets/Images/Welcome/WelcomeImage.svg";
import welcomeImageMobile from "../../../assets/Images/Welcome/WelcomeImageMobile.svg";

export const StartContainer = styled.div`
  .buttonContainer {
    margin: 10px auto 20px;
    max-width: 180px;
  }
`;

export const ImageHeader = styled.div`
  background-image: url(${welcomeImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 10%;
  width: 100%;
  height: 350px;

  /* Hasta 301px */
  @media (max-width: 301px) {
      background-image: url(${welcomeImageMobile});
      height: 200px;
  }
  
  /* 302px - 321px */
  @media (min-width: 302px) and (max-width: 321px) {
      background-image: url(${welcomeImageMobile});
      height: 210px;
  }
  
  /* 322px - 341px */
  @media (min-width: 322px) and (max-width: 341px) {
      background-image: url(${welcomeImageMobile});
      height: 220px;
  }
  
  /* 342px - 351px */
  @media (min-width: 342px) and (max-width: 351px) {
      background-image: url(${welcomeImageMobile});
      height: 230px;
  }
  
  /* 352px - 371px */
  @media (min-width: 352px) and (max-width: 371px) {
      background-image: url(${welcomeImageMobile});
      height: 240px;
  }
  
  /* 372px - 391px */
  @media (min-width: 372px) and (max-width: 391px) {
      background-image: url(${welcomeImageMobile});
      height: 250px;
  }
  
  /* 392px - 401px */
  @media (min-width: 392px) and (max-width: 401px) {
      background-image: url(${welcomeImageMobile});
      height: 260px;
  }
  
  /* 402px - 421px */
  @media (min-width: 402px) and (max-width: 421px) {
      background-image: url(${welcomeImageMobile});
      height: 270px;
  }
  
  /* 422px - 441px */
  @media (min-width: 422px) and (max-width: 441px) {
      background-image: url(${welcomeImageMobile});
      height: 280px;
  }
  
  /* 442px - 480px */
  @media (min-width: 442px) and (max-width: 480px) {
      background-image: url(${welcomeImageMobile});
      height: 265px;
  }  

`;

export const ImageSvgRes = styled.div`
  & > svg {
    width: 100%;
    height: 266px;
  }

  .fullImage {
    display: none;
  }

  @media (min-width: 480px) {
    .fullImage {
      display: block;
    }

    & > svg {
      width: 100%;
      height: 266px;
    }

    .cropImage {
      display: none;
    }
  }
`;
