export const CheckIcon = () => {
  return (
    <svg width="19" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m2 7 5.25 5L17 2"
        stroke="#000000"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
