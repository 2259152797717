
const parseRecommentaions = (recommendatinos: any) => {
  return recommendatinos.map((item: any) => (
    {
      name: item.name.trim(),
      price: item.price,
      remark: item.remark
    }
  ))
}

export {
  parseRecommentaions
}