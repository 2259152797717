export const T = {
  // Colors
  primary: '#003538',
  secondary: '#E76C0A',
  primaryTransparent: 'rgba(0, 0, 0, 0.7)',
  secondaryTransparent: 'rgba(255, 255, 255, 0.7)',
  grayStepBar: '#E5E5E5',
  gray: 'rgb(176, 176, 176)',
  blue: '#2F80ED',
  yellow: 'rgb(255, 205, 60)',
  // Fonts
  font: {
    primary: 'Inter, sans-serif',
    secondary: 'Inter, sans-serif',
  },
  // Font sizes
  fontSizes: {
    small: '12px',
    medium: '14px',
    large: '16px',
  },
  // Font colors
  fontColors: {
    white: '#FFFFFF',
    black: '#000000',
  },
  // Font weights
  fontWeights: {
    light: '300',
    regular: '400',
    bold: '700',
  },
  // Line heights
  lineHeights: {
    small: '13px',
    medium: '20px',
    large: '24px',
  },
  // Letter spacings
  letterSpacings: {
    small: '0.5px',
    medium: '1px',
    large: '1.5px',
  },
  // Border radius
  borderRadius: {
    button: '50px',
  }
};