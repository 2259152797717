import axios from "axios"
import { HUBSPOT_API_URL } from "../../env"

const getQuestionKey = (questionId: number) => {
  
  switch (questionId) {
    case 1: return "city"
    case 3: return "programa_de_interes_"
    case 9:
    case 4: return "ocupacion_actual"
    case 5: return "metodo_de_pago"

    case 11:
    case 6: return "cuando_tienes_pensado_iniciar_este_programa_"
    default: return ""
  }
}

const parseAnswer3 = (text: string) => {
  const value = text.trim()

  if (value === "Tec. Lab. Mecánica Automotriz") {
    return "Técnico Laboral Por Competencias En Mecánica Automotriz"
  }

  if (value === "Tec. Lab. Auxiliar Atención Integral A La Primera Infancia") {
    return "Técnico Laboral Por Competencias En Auxiliar Atención Integral A La Primera Infancia"
  }

  if (value === "Tec. Lab. Auxiliar Administrativo") {
    return "Técnico Laboral Por Competencias En Auxiliar Administrativo"
  }

  if (value === "Tec. Lab. Auxiliar Contable") {
    return "Técnico Laboral Por Competencias En Auxiliar Contable"
  }

  if (value === "Tec. Lab. Auxiliar Administrativo En Salud") {
    return "Técnico Laboral Por Competencias En Auxiliar Administrativo En Salud"
  }

  if (value === "Tec. Lab. Auxiliar En Enfermería") {
    return "Técnico Laboral Por Competencias De Auxiliar En Enfermería"
  }

  if (value === "Tec. Lab. Auxiliar En Salud Oral") {
    return "Técnico Laboral Por Competencias En Auxiliar En Salud Oral"
  }

  if (value === "Tec. Lab. Servicios Farmacéuticos") {
    return "Técnico Laboral Por Competencias En Servicios Farmacéuticos"
  }

  if (value === "Otro") {
    return "NO HA DEFINIDO"
  }

}

const parseAnswer4 = (text: string) => {
  const value = text.trim()

  if (value === "Estudiante bachiller") {
    return "Estudiante básica/media"
  }

  if (value === "Trabajo en una empresa") {
    return "Empleado"
  }

  if (value === "Trabajo como independiente") {
    return "Independiente"
  }

  if (value === "No trabajo") {
    return "Desempleado"
  }

  return value
}

const parseAnswer5 = (text: string) => {
  const value = text.trim()

  if (value === "Efectivo") {
    return "Efectivo"
  }

  if (value === "Tarjetas Débito / Crédito") {
    return "Tarjetas Débito / Crédito"
  }

  if (value === "Préstamo bancario") {
    return "Préstamo Bancario"
  }

  if (value === "Cesantías") {
    return "Cesantías"
  }

  if (value === "Crédito directo") {
    return "Crédito Directo"
  }

  return value
}

const parseHubspotData = (answersData: Array<any>, contactData: any, services: Array<any>) => {
  const hubspotData: any = {
    properties: {
      email: contactData.email,
      // correo_electronico: contactData.email,
      firstname: contactData.name,
      lastname: contactData.familyName,
      phone: contactData.phone,
      // telefono_celular: contactData.phone,
    }
  }

  // if (services.length > 0 && services[0].type?.name  === "service") {
  //   hubspotData.properties['programa_de_interes_'] = services[0]?.name?.trim() || ""
  // }

  answersData.forEach((answer: any) => {
    const questionKey = getQuestionKey(answer.id_question)
    if (questionKey) {
      hubspotData.properties[questionKey] = (typeof(answer.alternative_text) === 'string') ? answer.alternative_text.trim() : answer.alternative_text.join(';')
    }
  })

  // if (hubspotData.properties.quien_paga_sus_estudios_) {
  //   hubspotData.properties.quien_paga_sus_estudios_ = parseAnswer4(hubspotData.properties.quien_paga_sus_estudios_)
  // }

  if (hubspotData.properties.programa_de_interes_) {
    hubspotData.properties.programa_de_interes_ = parseAnswer3(hubspotData.properties.programa_de_interes_)
  }

  if (hubspotData.properties.ocupacion_actual) {
    hubspotData.properties.ocupacion_actual = parseAnswer4(hubspotData.properties.ocupacion_actual)
  }

  if (hubspotData.properties.metodo_de_pago) {
    hubspotData.properties.metodo_de_pago = parseAnswer5(hubspotData.properties.metodo_de_pago)
  }

  return hubspotData
}


const addContactToHubspot = async (data: any) => {

  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  }

  return await axios.post(`${HUBSPOT_API_URL}`, data, config)
};

export {
  parseHubspotData,
  addContactToHubspot
}