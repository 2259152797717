export const ADVISER_IDENTIFIER = '548105bc-a52a-4d44-a7ec-16d4cf3be077'
export const QUESTIONS_API_URL: string = 'https://d21tr6z4xhmruo.cloudfront.net'
export const SERVICES_API_URL: string = 'https://d33w76sjq8vxbi.cloudfront.net'
export const PREDICT_API_URL: string = 'https://kvzwybhos3.execute-api.us-east-1.amazonaws.com/dev'
export const ANSWERS_API_URL: string = "https://d1vmmc9g154f9b.cloudfront.net"
export const CHAT_RECORDS_API_URL: string = "https://ep6wdvphi5.execute-api.us-east-1.amazonaws.com/dev"
export const CONTACT_API_URL: string = "https://dia29x7l1g5qs.cloudfront.net"
export const ANALYTICS_API_URL: string = "https://b5kjzvxwik.execute-api.us-east-1.amazonaws.com/dev"
export const SENDGRID_SERVICE_URL: string = "https://u16g93pgve.execute-api.us-east-1.amazonaws.com/prod/send-recommended-notification"
export const EMAIL_TO: string = ""
export const POSITION_RIGHT_VALID_URLS = new Set([""])
export const CHAT_BETA_URL: string = "https://iknmh7qqhj.execute-api.us-east-1.amazonaws.com/dev" // new version
export const SEND_PROMPT_API: string = "https://wlebpojmpi.execute-api.us-east-1.amazonaws.com/dev" // Generic one
export const SENDGRID_RECOMMENTATIONS_URL: string = "https://u16g93pgve.execute-api.us-east-1.amazonaws.com/deploy/send-recommendations-to-userXXX"
export const ADVISER_LOGO_URL: string = "https://tryadviser-images.s3.amazonaws.com/advisers-logos/ADVISER.png";
export const HUBSPOT_API_URL: string = "https://j6kekdkk7b.execute-api.us-east-1.amazonaws.com/dev/api/v1/contact/"
export const CRM_INTERACTION_URL: string = "https://j6kekdkk7b.execute-api.us-east-1.amazonaws.com/dev"
export const RECAPTCHA_SITE_KEY: string = process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""